body {
  margin: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 1;
  background-color: #080618;
  padding-top: 50px;
}
section {
  max-width: 1800px;
  margin-left: auto;
  margin-right: auto;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* -------------------------------
  
      01. Custom styles
  
  ---------------------------- */
span.quote {
  display: block;
  margin: 20px 0px;
  font-style: italic;
  font-size: 110%;
  font-weight: bold;
  text-indent: 10%;
}
span.quote.reference {
  display: block;
  margin: 20px 0px;
  font-style: italic;
  font-size: 90%;
  font-weight: bold;
  text-indent: 10%;
}

.hide {
  display: none !important;
}

[class*="uk-list"] > ::before {
  content: "";
  position: relative;
  left: -60px !important;
  width: 30px;
  height: 1.5em;
  margin-bottom: -1.5em;
  display: list-item;
  list-style-position: inside;
  text-align: right;
}

* + ul {
  margin-top: 0px !important;
}
@media (min-width: 960px) {
  body {
    padding-top: 0;
    font-size: 13px;
  }
}

.abt-mobile {
  background-image: url("img/about1.png");
  background-size: 100%;
  display: block;
}

@media (min-width: 960px) {
  body {
    background-image: url("img/bg.svg");
    background-repeat: no-repeat;
    background-position: top center;
    background-size: 100% auto;
  }
  .abt-mobile {
    display: none !important;
  }
}

@media (min-width: 1440px) {
  body {
    font-size: 16px;
  }
}

body.loading {
  overflow: hidden;
}

body,
a,
.uk-h1,
.uk-h2,
.uk-h3,
.uk-h4,
.uk-h5,
.uk-h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  color: #c9c9e3;
}

a {
  color: white;
  text-decoration: none;
  -webkit-transition: color 0.4s ease;
  transition: color 0.4s ease;
}

a:hover {
  color: #c9c9e3;
  text-decoration: none;
}

p {
  line-height: 1.5;
}

.uk-h1,
.uk-h2,
.uk-h3,
.uk-h4,
.uk-h5,
.uk-h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
}

.uk-h2 {
  font-size: 32px;
}

@media (min-width: 1600px) {
  .uk-h2 {
    font-size: 48px;
  }
}

.uk-h3 {
  font-size: 21px;
}

@media (min-width: 1600px) {
  .uk-h3 {
    font-size: 32px;
  }
}

.container--min {
  max-width: 1140px;
}

.container--panel {
  max-width: 1800px;
}

.uk-container-large {
  padding-left: 0;
  padding-right: 0;
  max-width: 1920px;
}

.uk-container-expand:not(.container--special) .uk-grid:not(.grid--wide) {
  margin-left: 0;
}

.boxshadow,
.slider__control {
  -webkit-box-shadow: 12px 12px 50px rgba(8, 6, 24, 0.75);
  box-shadow: 12px 12px 50px rgba(8, 6, 24, 0.75);
}

/* ----------------------------
  
      02. Preloader
  
  ---------------------------- */
.preloader {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 10000;
  background: #080618 url("img/preloader.svg") no-repeat center;
  background-size: 60px auto;
}

/* ----------------------------
  
      03. Buttons and Form Fields
  
  ---------------------------- */
.btn,
input.btn {
  display: inline-block;
  font-size: 13px;
  font-weight: 700;
  text-align: center;
  text-transform: uppercase;
  color: white;
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#ff0042),
    color-stop(#6d02e5),
    color-stop(#ff0042),
    to(#ff0042)
  );
  background: linear-gradient(to right, #ff0042, #6d02e5, #ff0042, #ff0042);
  background-size: 300% 100%;
  border: none;
  border-radius: 0;
  -webkit-box-shadow: 0 0 30px rgba(8, 6, 24, 0.3);
  box-shadow: 0 0 30px rgba(8, 6, 24, 0.3);
  -webkit-appearence: none;
  cursor: pointer;
  -webkit-transition: background 0.4s ease;
  transition: background 0.4s ease;

  padding: 0px 10px;
  vertical-align: middle !important;
  overflow-wrap: normal;
}

@media (min-width: 1600px) {
  .btn,
  input.btn {
    font-size: 16px;
  }
}

.btn:hover,
input.btn:hover {
  color: white;
  background-position: 100% 100%;
}

.btn--disabled,
.btn--disabled:hover,
input.btn--disabled,
input.btn--disabled:hover {
  background: transparent;
  border: 3px solid #5a5caa;
  color: #5a5caa;
  -webkit-box-shadow: none;
  box-shadow: none;
  cursor: default;
  overflow: hidden;
  font-size: 85%;
}

.btn .icon--dots,
input.btn .icon--dots {
  margin-left: 16px;
}

.uk-input,
.uk-select,
.uk-textarea {
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  line-height: 1.5;
  padding: 0;
  background: transparent;
  color: #c9c9e3;
  border: none;
  border-bottom: 2px solid #9294c7;
}

.uk-input:focus,
.uk-select:focus,
.uk-textarea:focus {
  color: #c9c9e3;
  background: transparent;
  border-color: #c9c9e3;
}

::-webkit-input-placeholder {
  color: #c9c9e3;
  opacity: 0.8;
}

::-moz-placeholder {
  color: #c9c9e3;
  opacity: 0.8;
}

:-ms-input-placeholder {
  color: #c9c9e3;
  opacity: 0.8;
}

:-moz-placeholder {
  color: #c9c9e3;
  opacity: 0.8;
}

/* ----------------------------
  
      04. Custom Icons
  
  ---------------------------- */
.icon {
  display: inline-block;
  background: transparent none no-repeat center;
  border: none;
  outline: none;
}

.icon--dots {
  width: 26px;
  height: 10px;
  background-image: url("img/icons/dots.svg");
}

.icon--play {
  width: 27px;
  height: 27px;
  background-image: url("img/icons/play.svg");
}

.icon--play_large {
  width: 51px;
  height: 53px;
  background-image: url("img/icons/play_large.svg");
}

.icon--pause {
  width: 27px;
  height: 27px;
  background-image: url("img/icons/pause.svg");
}

.icon--volume {
  width: 31px;
  height: 31px;
  background-image: url("img/icons/volume.svg");
}

/* ----------------------------
  
      05. Hover Images
  
  ---------------------------- */
.cover__photo {
  display: block;
  position: relative;
  padding-bottom: 100%;
}

.cover__photo::after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #080618;
  opacity: 0;
  -webkit-transition: opacity 0.4s ease;
  transition: opacity 0.4s ease;
}

.cover__photo:hover::after {
  opacity: 0.5;
}

.cover__photo--1-2 {
  padding-bottom: 50%;
}

.cover__link {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 50px;
  height: 50px;
  margin-left: -25px;
  margin-top: -25px;
  background-color: #ff0042;
  opacity: 0;
  z-index: 2;
}

/* ----------------------------
  
      06. Modal Windows
  
  ---------------------------- */
.uk-modal {
  padding-top: 60px;
}

.modal.uk-modal-dialog {
  width: 100%;
  background-color: #c9c9e3;
}

.modal.uk-modal-dialog p,
.modal.uk-modal-dialog a:not(.btn),
.modal.uk-modal-dialog .uk-h1,
.modal.uk-modal-dialog .uk-h2,
.modal.uk-modal-dialog .uk-h3,
.modal.uk-modal-dialog .uk-h4,
.modal.uk-modal-dialog .uk-h5,
.modal.uk-modal-dialog .uk-h6,
.modal.uk-modal-dialog h1,
.modal.uk-modal-dialog h2,
.modal.uk-modal-dialog h3,
.modal.uk-modal-dialog h4,
.modal.uk-modal-dialog h5,
.modal.uk-modal-dialog h6 {
  color: #1e1a3f;
}

.modal__overflow::-webkit-scrollbar {
  width: 28px;
}

.modal__overflow::-webkit-scrollbar-track {
  background-color: #9294c7;
  border-left: 23px solid #c9c9e3;
  border-right: 3px solid #c9c9e3;
}

.modal__overflow::-webkit-scrollbar-thumb {
  background-color: #6d02e5;
  border-left: 20px solid #c9c9e3;
}

.modal p {
  font-size: 14px;
  line-height: 1.7;
}

.modal__close {
  right: 0;
  background-color: #6d02e5;
  padding: 13px;
  -webkit-transform: scale(1.3) translate(0, -100%);
  transform: scale(1.3) translate(0, -100%);
  -webkit-transform-origin: top right;
  transform-origin: top right;
  -webkit-transition: background-color 0.4s ease;
  transition: background-color 0.4s ease;
}

.modal__close:hover {
  background-color: #ff0042;
}

.modal__profile {
  padding: 0;
  color: #080618;
  background-color: #c9c9e3;
  max-width: 550px !important;
}

.modal__profile__title {
  font-size: 18px;
  color: #080618;
  font-weight: 700;
  margin-bottom: 7px;
}

.modal__profile__subtitle {
  font-size: 18px;
}

.modal__content {
  text-align: center;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 0;
}

.modal__cover {
  position: relative;
  height: 150px;
}

.modal__cover::after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #080618;
  opacity: 0.5;
}

.modal__avatar {
  background-size: 120% auto;
  background-position: 50% 0%;
  width: 165px;
  height: 165px;
  margin: -83px auto 0;
  position: relative;
  z-index: 2;
}

@media (min-width: 1200px) {
  .modal__content {
    padding-left: 40px;
    padding-right: 40px;
    padding-bottom: 20px;
  }

  .modal__cover {
    height: 200px;
  }
}

/* ----------------------------
  
      07. Navigation
  
  ---------------------------- */
.nav a {
  font-size: 18px;
  font-weight: 700;
  letter-spacing: 0.1em;
}

.nav--panel,
.nav--footer {
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}

.nav--panel li,
.nav--footer li {
  display: block;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
}

.nav--panel .uk-active a {
  color: #ff0042;
}

.nav--offcanvas {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  height: 100%;
  padding: 10% 0;
  margin: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.nav--offcanvas li {
  list-style: none;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}

@media (min-height: 480px) and (orientation: portrait) {
  .nav--offcanvas {
    padding: 30% 0;
  }
}

.nav--intro li {
  margin-bottom: 15px;
}

@media (min-width: 1200px) {
  .nav--intro li {
    margin-bottom: 20px;
  }
}

@media (min-width: 1600px) {
  .nav--intro li {
    margin-bottom: 20px;
  }
}

.nav--intro a {
  position: relative;
}

.nav--intro a::before {
  content: "";
  display: block;
  position: absolute;
  left: -100px;
  top: 8px;
  width: 0;
  height: 2px;
  background-color: #ff0042;
  -webkit-transition: width 0.4s ease;
  transition: width 0.4s ease;
}

.nav--intro a:hover {
  color: #ff0042;
}

.nav--intro a:hover::before {
  width: 20px;
}

@media (min-width: 960px) {
  .nav--intro a {
    font-size: 13px;
  }
}

@media (min-width: 1600px) {
  .nav--intro a {
    font-size: 18px;
  }
}

.nav--footer li {
  padding: 0 10px 20px;
}

.nav--footer li a {
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0.1em;
  color: #9294c7;
}

@media (min-width: 960px) {
  .nav--footer li {
    padding: 0 10px;
  }
}

.wide {
  width: 100%;
}

/* ----------------------------
  
      08. Fixed Top Panel
  
  ---------------------------- */
.panel {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 100%;
  height: 50px;
  background-color: rgba(30, 26, 63, 0.9);
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.panel a {
  font-size: 14px;
  font-weight: 700;
}

.panel__player {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.panel__player > * {
  margin: 0 7px;
}

.panel__player .icon--play-pause {
  margin: 0;
  cursor: pointer;
}

@media (min-width: 1200px) {
  .panel {
    height: 75px;
  }
}

/* ----------------------------
  
      09. Logo
  
  ---------------------------- */
.logo--intro {
  margin-bottom: 20px;
}

@media (min-width: 960px) {
  .logo--intro {
    margin-bottom: 40px;
  }
}

@media (min-width: 1200px) {
  .logo--intro {
    margin-bottom: 60px;
  }
}

@media (min-width: 1600px) {
  .logo--intro {
    margin-bottom: 90px;
  }
}

/* ----------------------------
  
      10. Social Media Buttons
  
  ---------------------------- */
.social__list .fab {
  font-size: 18px;
  -webkit-transition: color 0.4s ease;
  transition: color 0.4s ease;
}

.social__wrapper {
  position: absolute;
  left: 40px;
  bottom: 0;
}

@media (min-width: 1200px) {
  .social__wrapper {
    left: 70px;
  }
}

@media (min-width: 1600px) {
  .social__wrapper {
    left: 90px;
  }
}

.social--intro {
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
  position: absolute;
  left: 0;
  bottom: 0;
}

.social--intro .social__title {
  white-space: nowrap;
  text-align: right;
  margin-bottom: 0;
  font-size: 13px;
}

@media (min-width: 1600px) {
  .social--intro .social__title {
    font-size: 16px;
  }
}

.social--intro .social__list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
  -webkit-box-align: baseline;
  -ms-flex-align: baseline;
  align-items: baseline;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  text-align: center;
  margin: 15px 0 0;
}

.social--intro .social__list li:not(:last-child) {
  padding-left: 20px;
}

.social--intro .social__list a:hover {
  color: #ff0042;
}

.social--intro .social__list .fab {
  min-width: 18px;
  font-size: 18px;
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}

@media (min-width: 1600px) {
  .social--intro .social__list li {
    padding-left: 50px;
  }

  .social--intro .social__list .fab {
    min-width: 28px;
    font-size: 28px;
  }
}

.social--modal,
.social--footer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.social--modal li,
.social--footer li {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  display: inline-block;
  padding-left: 20px;
  padding-right: 20px;
}

.social--modal .social__list .fab {
  color: #6d02e5;
}

.social--modal .social__list .fab:hover {
  color: #ff0042;
}

.social--footer .social__list {
  margin: 30px auto;
}

.social--footer .social__list .fab {
  color: #c9c9e3;
}

.social--footer .social__list .fab:hover {
  color: #ff0042;
}

/* ----------------------------
  
      12. Events Date
  
  ---------------------------- */
.events__block {
  padding: 15px;
  background-color: #6d02e5;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.events__date {
  position: relative;
  display: inline-block;
  font-family: "Overpass Mono", sans-serif;
}

.events__day {
  font-size: 32px;
}

.events__month {
  font-size: 46px;
  line-height: 46px;
  padding-right: 5px;
}

.events__time {
  font-size: 24px;
  letter-spacing: 0.015em;
}

.events__year {
  position: absolute;
  top: -0.4em;
  right: -1;

  font-size: 13px;
  letter-spacing: 0.4em;
  text-align: right;
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
  -webkit-transform-origin: right top;
  transform-origin: right top;
}

.events__location {
  font-size: 14px;
  padding-left: 30px;
}

.events__venue {
  font-size: 21px;
  display: block;
  margin-bottom: 8px;
  font-weight: 500;
}

.events__booking .btn {
  display: block;
}

.events--intro {
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
  z-index: 5;
}

.events--intro .events__title {
  margin-bottom: 0;
}

.events--intro .events__title__wrap {
  -ms-flex-item-align: end;
  align-self: flex-end;
  padding-left: 0;
}

@media (min-width: 640px) {
  .events__table__address {
    padding-left: 40px;
  }
}

@media (min-width: 1200px) {
  .events__grid--intro {
    margin-left: -50px;
  }

  .events__table .btn {
    padding-top: 16px;
    padding-bottom: 16px;
  }
}

/* ----------------------------
  
      13. Intro Section
  
  ---------------------------- */
.intro__title {
  margin-bottom: 30px;
}

@media (max-width: 959px) {
  .intro__title {
    max-width: 670px;
    margin-left: auto;
    margin-right: auto;
  }
}

@media (min-width: 1600px) {
  .intro__title {
    margin-bottom: 70px;
  }
}

.intro__title span:not(.uk-hidden) {
  font-size: 28px;
  line-height: 1;
  margin-bottom: 16px;
  padding: 4px 8px;
  display: inline-block;
  text-align: left;
  color: #c9c9e3;
  background-color: rgba(90, 92, 170, 0.8);
}

@media (min-width: 640px) {
  .intro__title span:not(.uk-hidden) {
    font-size: 36px;
  }
}

@media (min-width: 1200px) {
  .intro__title span:not(.uk-hidden) {
    font-size: 38px;
    padding: 7px 10px;
  }
}

@media (min-width: 1600px) {
  .intro__title span:not(.uk-hidden) {
    font-size: 60px;
    padding: 8px 20px;
  }
}

.intro__sidebar {
  padding: 20px 20px 150px 0px;

  /* max-width: 200px; */
}

@media (min-width: 1560px) {
  .intro__sidebar {
    /* padding: 50px 100px 0px 0px; */
    margin: 0px 150px 0px -150px;
  }
}

@media (min-width: 1600px) {
  .intro__sidebar {
    min-height: 520px;
  }
}

/* ----------------------------
  
      14. About Section
  
  ---------------------------- */
@media (max-width: 1000px) {
  .about__info .uk-text-right {
    margin-left: 0px !important;
    text-align: left !important;
  }
}

@media (min-width: 960px) {
  .about__grid {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
}

.smaller {
  padding-left: 50px;
}

section.intro {
  background-image: none;
}

@media (min-width: 960px) {
  section.intro {
    background-image: url("img/about1.png");
    background-size: 45%;
    background-repeat: no-repeat;
    background-clip: padding-box;
    background-position: left 15% top 70%;
  }
}

.about__info {
  padding: 15px;
}
.about__info p,
.nav li a {
  text-shadow: #15132c -1px 1px 2px, #15132c 1px -1px 2px, #15132c -1px -1px 2px,
    #15132c 1px 1px 2px, #15132c 0px 0px 2px, #15132c 0px 0px 2px,
    #15132c 0px 0px 2px, #15132c 0px 0px 2px, #15132c 0px 0px 2px,
    #15132c 0px 0px 2px, #15132c 0px 0px 2px;
}

@media (min-width: 640px) {
  .about__info {
    padding: 0 30px;
  }
}

@media (min-width: 960px) {
  .about__info {
    padding: 30px;
  }
}

@media (min-width: 1200px) {
  .about__info {
    padding-left: 140px;
    max-width: 1635px;
    margin-right: auto;
  }
}

@media (min-width: 1600px) {
  .about__info {
    padding-left: 20%;
    padding-right: 0;
  }
}

/* ----------------------------
  
      15. Team Section
  
  ---------------------------- */
.team {
  position: relative;
}

@media (max-width: 959px) {
  .team .uk-container {
    max-width: 670px;
  }
}

.team__title {
  color: #9294c7;
}

@media (min-width: 1300px) {
  .team__title {
    position: absolute;
    right: calc(100% + 20px);
    top: 0;
    width: 100%;
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
  }

  .team__title_inner {
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
    display: inline-block;
    width: 100%;
    height: 0;
    text-align: right;
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
  }
}

@media (min-width: 1440px) {
  .team__title {
    right: calc(100% + 40px);
  }
}

.team__member:hover {
  cursor: pointer;
}

.team__member:hover .cover__photo::after {
  opacity: 0.5;
}

.team__member:hover .cover__link {
  opacity: 1;
}

.team__member:hover .cover__name {
  color: #c9c9e3;
}
.team__members img {
  max-height: 420px;
}

@media (min-width: 960px) {
  .team__member__wrap:nth-child(even) {
    margin-top: 50px;
  }
}

.team .cover__photo {
  margin-bottom: 30px;
  padding-bottom: 138%;
}

.team .cover__link {
  background-color: #6d02e5;
}

.team__name,
.team__vocation {
  display: block;
}

.team__name {
  font-weight: 700;
  margin-bottom: 8px;
}

.team__vocation {
  color: #9294c7;
}

/* ----------------------------
  
      16. Music Section and Audio Player
  
  ---------------------------- */

.mejs__playpause-button button,
.mejs__fullscreen-button button {
  width: 27px;
  height: 27px;
  background-color: transparent;
  border: none;
  display: inline-block;
  background: transparent none no-repeat center;
  cursor: pointer;
}

.playlist__album {
  margin-bottom: 30px;
}

@media (max-width: 639px) {
  .playlist .albums__item {
    margin-bottom: 0;
    padding: 15px;
  }
}

@media (min-width: 640px) {
  .playlist {
    padding: 10px;
  }

  .playlist__album {
    margin-bottom: 10px;
  }
}

.list--striped {
  margin: 0;
  padding: 0;
}

.list__item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 9px;
}

.list__item:nth-child(odd) {
  background-color: rgba(47, 44, 91, 0.5);
}

@media (min-width: 640px) {
  .tracks .list__item {
    /* padding: 15px; */
    min-height: 15px;
  }
}
.tracks {
  padding: 0;
}

.tracks .list__item {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  cursor: hover !important;
}

.tracks .list__item .icon--play-pause {
  position: absolute;
  top: 0;
  left: 0px;
  height: 100%;
  cursor: pointer;
}

.tracks .list__item .mejs__time span.mejs__currenttime {
  display: none;
}

.tracks .list__item .mejs__time span.mejs__duration {
  display: block;
}

.tracks .list__item--active {
  background-color: #6d02e5;
}

.tracks .list__item--active .mejs__time span.mejs__currenttime {
  display: block;
}

.tracks .list__item--active .mejs__time span.mejs__duration {
  display: none;
}

.tracks .list__item:hover {
  cursor: pointer;
  background-color: #ff0042;
}

.tracks .list__item:hover .tracks__control,
.tracks .list__item:hover .tracks__title,
.tracks .list__item:hover .tracks__time,
.tracks .list__item:hover .tracks__volume {
  opacity: 1;
  color: white;
}

.tracks__volume_mask {
  position: absolute;
  width: 0;
  height: 0;
}

.tracks__title {
  position: absolute;
  top: 0;
  left: 55px;
  right: 70px;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  overflow: hidden;
}

.tracks__time {
  padding-left: 20px;
  min-height: 14px;
}

@media (max-width: 639px) {
  .tracks {
    font-size: 14px;
  }
}

@media (min-width: 640px) {
  .tracks__title {
    left: 40px;
  }

  .tracks .list__item .icon--play-pause {
    left: 5px;
  }
}

.albums__cover {
  width: 35%;
  margin-right: 15px;
  -ms-flex-negative: 0;
  flex-shrink: 0;
}

@media (min-width: 480px) {
  .albums__cover {
    max-width: 165px;
    width: 100%;
    margin-right: 30px;
  }
}

.albums__title,
.albums__year {
  display: block;
  line-height: 1.5;
}

@media (min-width: 480px) {
  .albums__title,
  .albums__year {
    font-size: 18px;
  }
}

.albums__title {
  font-weight: 700;
}

.albums__item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 30px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

@media (min-width: 640px) and (max-width: 959px) {
  .albums--list {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
  }

  .albums--list .albums__item {
    -webkit-box-flex: 1;
    -ms-flex: 1 0 50%;
    flex: 1 0 50%;
    max-width: 50%;
    padding-right: 20px;
  }
}

.albums--playlist {
  -webkit-box-pack: left;
  -ms-flex-pack: left;
  justify-content: left;
}

.albums--playlist .albums__buy {
  text-align: center;
  -webkit-transform: rotate(-90deg) translate(0, 50%);
  transform: rotate(-90deg) translate(0, 50%);
}

.albums--playlist .albums__buy_buttons {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.albums--list .albums__item.albums--paused .albums__cover .cover__link {
  background-image: url("img/icons/play.svg");
}

.albums--list .albums__item.uk-active .albums__title {
  color: #c9c9e3;
}

.albums--list .albums__item.uk-active .albums__buy {
  margin-top: 20%;
  opacity: 1;
  visibility: visible;
}

.albums--list .albums__item:not(.uk-active) .albums__title {
  color: white;
}

@media (max-width: 639px) {
  .albums__title {
    font-size: 14px;
  }

  .albums--list .albums__item.uk-active .albums__buy {
    font-size: 18px;
  }
}

/* ----------------------------
  
      19. Contacts Section
  
  ---------------------------- */
.contacts__info {
  font-size: 14px;
  font-weight: 700;
  line-height: 1.8;
  letter-spacing: 0.1em;
}

.contacts__subtitle {
  letter-spacing: 0;
  font-size: 18px;
}

.contacts__form {
  padding: 15px;
}

@media (min-width: 640px) {
  .contacts__form {
    padding: 30px;
  }
}

.js-field-error {
  border-bottom-color: #ff0042;
}

/* ----------------------------
  
      20. Footer
  
  ---------------------------- */
.footer {
  position: relative;
  text-align: center;
}

.footer .section_container {
  margin-top: -30px;
}

@media (min-width: 640px) {
  .footer .section_container {
    margin-top: -60px;
  }
}

.footer__totop {
  position: absolute;
  top: -30px;
  width: 50px;
  height: 50px;
  padding-top: 20px;
  margin-left: -25px;
  text-align: center;
  background-color: #1e1a3f;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.footer__totop svg {
  -webkit-transform: scale(1);
  transform: scale(1);
  fill: white;
}

.footer__totop.uk-icon:not(.uk-preserve) [stroke*="#"]:not(.uk-preserve) {
  stroke: white;
  -webkit-transition: stroke 0.4s ease;
  transition: stroke 0.4s ease;
}

@media (min-width: 1200px) {
  .footer__totop:hover {
    padding-bottom: 20px;
    top: -50px;
  }

  .footer__totop:hover.uk-icon:not(.uk-preserve)
    [stroke*="#"]:not(.uk-preserve) {
    stroke: #c9c9e3;
  }
}

@media (min-width: 1600px) {
  .footer__totop {
    top: -50px;
    width: 80px;
    height: 80px;
    padding-top: 30px;
    margin-left: -45px;
  }

  .footer__totop svg {
    -webkit-transform: scale(2);
    transform: scale(2);
  }

  .footer__totop:hover {
    padding-bottom: 20px;
    top: -70px;
  }
}

.footer__copyrights {
  font-size: 14px;
  color: #9294c7;
}
/* ----------------------------
  
      22. Colors
  
  ---------------------------- */

.c-dark--1 {
  color: #1e1a3f;
}

.c-dark--2 {
  color: #2f2c5b;
}

.c-dark--3 {
  color: #5a5caa;
}

.c-dark--4 {
  color: #9294c7;
}

.c-purple {
  color: #6d02e5;
}

.c-red {
  color: #ff0042;
}

.c-light {
  color: #c9c9e3;
}

.bg-dark {
  background-color: hsl(247, 60%, 6%);
}

.bg-dark--1 {
  background-color: #1e1a3f;
}

.bg-dark--2 {
  background-color: #2f2c5b;
}

.bg-dark--3 {
  background-color: #5a5caa;
}

.bg-dark--4 {
  background-color: #9294c7;
}

.bg-purple {
  background-color: #6d02e5;
}

.bg-red {
  background-color: #ff0042;
}

.bg-light {
  background-color: #c9c9e3;
}

::-moz-selection {
  background-color: #6d02e5;
}

::selection {
  background-color: #6d02e5;
}
.references-grid {
  display: grid;
  gap: 20px;
  grid-auto-flow: dense;
  grid-template-columns: 1fr; /* Single column by default */
}

@media (min-width: 768px) {
  /* Two-column layout with stacking on wider screens */
  .references-grid {
    grid-template-columns: repeat( 1fr); /* Two columns */
    grid-auto-rows: auto; /* Automatic row height */
  }
}

@media (min-width: 1024px) {
  /* Adjust grid for larger screens */
  .references-grid {
    grid-template-columns: 1fr ; /* Keep two-column layout */
  }
}

.reference-item {
  background: #15132c;
  padding: 0px;
  border-radius: 18px;
}

.modal__content {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

span.quote {
  font-size: 15px;
  font-weight: lighter;
}
.references-grid .modal__avatar {
  background: none !important
}
.modal__avatar img {
  background: none;
  border-radius: 50%;
}
.reflist li{
  line-height: 150%;
  /* list-style-type: none; */
font-size: 10px;
}